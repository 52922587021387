import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';

import Navbar from './Navbar';
import HomePage from './pages/HomePage';
import Presentation from './pages/presentation/Presentation';

import '../../styles/v2.css';

import logo from '../../assets/logo.png';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const location = useLocation().pathname.match(/v2\/([^\/]+)/);
  const currentActivePage = location ? location[1] : 'home';

  const [activePage, setActivePage] = useState<string>(currentActivePage);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');

  const { i18n, t } = useTranslation();

  const switchPage = useCallback((page: string) => {
    setActivePage(page);
  }, []);

  const darkModeClicked = useCallback(() => {
    setDarkMode(!darkMode);
  }, [darkMode]);

  const languageClicked = useCallback((lng: string) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    // force a reload to update the site
  }, [language]);

  // the code below allow to display the website in 
  // darkmode if the user has his system in darkmode
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setDarkMode(mediaQuery.matches);
  }, []);

  return (
    <div className={darkMode ? "dark" : ""}>
      <div className="transition duration-500 bg-neutral-50 dark:bg-neutral-900 dark:text-neutral-50">
        <div className="text-sm p-4 md:p-O mx-auto max-w-2xl">
            <div className="flex flex-row justify-between">
              
              <div className="py-2">
                <NavLink 
                  to="/v2"
                  onClick={() => switchPage('home')}
                >
                  <img className="object-scale-down w-6 rounded transition duration-200 hover:scale-110 dark:invert" src={logo} alt="logo joachim"/>
                </NavLink>
              </div>
              
              <div>
                <Navbar switchPage={switchPage} activePage={activePage} />
              </div>
              
              <div className="py-2">
                <button onClick={darkModeClicked} className="mt-1">
                  {!darkMode ? 
                    <svg 
                      className="fill-neutral-50 stroke-neutral-500 transition duration-300 hover:scale-110 hover:stroke-neutral-900" 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="18" height="18" 
                      viewBox="0 0 24 24" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="5"></circle>
                      <line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                    </svg> 
                  : 
                    <svg 
                      className="fill-neutral-300 transition duration-300 hover:scale-110 hover:fill-neutral-50" 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="18" 
                      height="18" 
                      viewBox="0 0 24 24" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                    </svg>
                  }
                </button>

                {/* TRANSLATIONS */}
                {/* <div>
                  <button onClick={() => languageClicked('fr')}>
                    français
                  </button>
                  <button onClick={() => languageClicked('en')}>
                    english
                  </button>
                </div> */}
 
              </div>
            </div>

            <div className="">
              <Routes>
                <Route path="*" Component={HomePage} />
                <Route path="/home" Component={HomePage} />
                <Route path="/presentation" Component={Presentation} />
              </Routes>
            </div>

            <div className="text-center mt-6 text-sm text-neutral-600 dark:text-neutral-400">
              Coded in Paris - November 2023
            </div>
        </div> 
      </div>
    </div>
  );


};

export default Home;