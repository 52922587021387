import React, { useState } from 'react';

// Props interface
interface ExperienceProps {
  company: string,
  location: string,
  title: string,
  period: string,
  contributions: string[]
}

const Experience: React.FC<ExperienceProps> = ({ company, location, title, period, contributions }) => {
  const [showContributions, setShowContributions] = useState(false);

  const toggleShow = () => {
    setShowContributions(!showContributions);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between">
        <div className="font-medium">{company}</div>
        <div className="flex flex-row">
          <div>{location}</div>
          <div 
            className="ml-2 w-6 sm:w-12 flex justify-end hover:cursor-pointer transition transition-500" 
            onClick={toggleShow}
          >
            <svg 
              className={"fill-neutral-600 dark:fill-neutral-300 stroke-2 transition transition-500 " + (showContributions ? "rotate-45" : "")} 
              width="24" 
              height="24" 
              viewBox="0 0 32 32" 
            >
              <path d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14 6.28 14 14-6.28 14.032-14 14.032zM23 15h-6v-6c0-0.552-0.448-1-1-1s-1 0.448-1 1v6h-6c-0.552 0-1 0.448-1 1s0.448 1 1 1h6v6c0 0.552 0.448 1 1 1s1-0.448 1-1v-6h6c0.552 0 1-0.448 1-1s-0.448-1-1-1z"></path>
            </svg>
          </div>
        </div>
      </div>

      <div 
        className={`overflow-hidden transition-max-height duration-500 ease-linear text-neutral-700 dark:text-neutral-400 ${(showContributions ? 'max-h-256' : 'max-h-0')}`}
        style={{ transform: 'scaleY(-1)' }}
      >
 
        <div style={{ transform: 'scaleY(-1)' }}>
          <div className="flex flex-row justify-between py-2">
            <div className="font-medium">{title}</div>
            <div>{period}</div>
          </div>
          <div>
            <ul className="list-disc">
              {contributions.map((contribution, index) => {
                return <li key={company + index} className="mt-1" >- {contribution}</li>
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>     
  )
};

export default Experience;
