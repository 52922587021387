import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Experience from './Experience';

import logoINSA from '../../../../assets/logoUniversities/insa.png';
import logoUTFSM from '../../../../assets/logoUniversities/utfsm.png';

const experiences = [
  {
    company:"Igocreate",
    location: "Paris, France",
    title: "Software Engineer", 
    period: "March 2020 - November 2022",
    contributions: [
      "worked on several platforms, mainly Witik, a web app helping companies to respect the new GDPR rules in Europe, follow their processes, automate several of them and create courses to teach those rules.",
      "developed the back-ends of the platforms with Node.JS and Express.js, and the front with a combination of React and Dust.js.",
      "build a PDF export service from the GDPR register using PDFKit, a PDF generation library for Node JS.",
      "wrote several tests (mainly functionals) using Mocha following TDD processes allowing us to achieve over 80% code coverage and implemented continuous integration in TOML using GitLab CI.",
      "participated in meetings with stakeholders, with discussions going from the current state of the different platforms to the interactions with the users and the platforms evolutions.",
    ]
  },
  {
    company:"Bottomline Technologies",
    location: "Geneva, Switzerland",
    title: "Software Engineer", 
    period: "April 2017 - August 2017",
    contributions: [
      "5 months internship during which I worked on a Javascript interface allowing users to visualize and edit data representing workflows from an SQL database.",
      "programmed a Java back-end allowing to access the SQL data and to interact with PL/SQL, the programming language used in the previous version of the workflows.",
      "worked with one other intern on this feature, in a team of 10 people, following agile practices with a daily scrum meeting, two weeks sprints and regular code reviews."
    ]
  },
  {
    company:"Kamoov",
    location: "Paris, France",
    title: "Software Engineer Intern", 
    period: "June 2016 - August 2016",
    contributions: [
      "3 months internship in a small startup incubated at Telecom ParisTech, working on an iOS live streaming app.",
      "worked in Objective-C to develop the several features of the app.",
      "developed part of the interface following mock-ups and instructions from the start-up founders, improved the stability of the apps and added social features."
    ]
  },
  {
    company:"France Nature Environnement",
    location: "Paris, France",
    title: "Android Developer Intern", 
    period: "Apr 2015 - Jun 2015",
    contributions: [
      "Development of an Android app using Java and Android Studio, displaying pollution datas for the city of Paris",
      "Use of several public APIs to get accesses to pollution datas"
    ]
  }
];

const Presentation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col gap-4 py-6">

      {/* PRESENTATION TITLE */}
      <div 
        className="animate-in flex flex-col mt-4"
        style={{ "--index": 1 } as React.CSSProperties}
      >
        <div className="text-md sm:text-lg font-bold">{t('presentationPage.presentation.title')}</div>
        <div className="text-sm sm:text-base text-neutral-500 dark:text-neutral-300 text-justify">
          <div>{t('presentationPage.presentation.shortPresentation1')}</div>
          <div>{t('presentationPage.presentation.shortPresentation2')}</div>
        </div>
      </div>

      {/* CV DOWNLOAD */}
      <div 
        className="animate-in"
        style={{ "--index": 1 } as React.CSSProperties}
      ></div>

      <div 
        className="animate-in flex flex-col items-center object-center text-neutral-700 dark:text-neutral-300 mt-4"
        style={{ "--index": 2 } as React.CSSProperties }
      >
        <div className="w-fit border border-neutral-400 dark:border-neutral-300 text-center py-2 px-4 rounded-lg hover:cursor-pointer hover:scale-105 transition duration-500">
          <Link to="/files/JoachimDorel_CV.pdf" target="_blank" download>
            <div className="flex flex-row ">
              <div className="dark:invert">
                <svg 
                  width="30px" 
                  height="30px" 
                  viewBox="0 0 24 24" 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none"
                >
                  <path 
                    stroke="#000000" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth="1" 
                    d="M12 11.5V20m0 0l3-3m-3 3l-3-3M8 7.036a3.484 3.484 0 011.975.99M17.5 14c1.519 0 2.5-1.231 2.5-2.75 0-1.265-.854-2.33-2.016-2.65A5 5 0 008.37 7.108a3.5 3.5 0 00-1.87 6.746"
                  />
                </svg>
              </div>
              <div className="font-semibold py-1 px-2">
                {t('presentationPage.presentation.cvDownload')}
              </div>

            </div>
          </Link>
        </div>
      </div>


      {/* PROFESSIONNAL EXPERIENCE */}
      <div className="flex flex-col text-sm sm:text-base gap-12 mt-6">
        <div 
          className="animate-in flex flex-col md:flex-row gap-2"
          style={{ "--index": 3 } as React.CSSProperties}
        >
          <div className="md:w-32 shrink-0 text-neutral-500 dark:text-neutral-300">
          {t('presentationPage.experience.title')}
          </div>
          <div className="text-neutral-800 dark:text-neutral-200 text-justify flex flex-col gap-2 w-full">
            {experiences.map((experience, index) => {
              return <Experience 
                        key={experience.company + index}
                        company={experience.company} 
                        location={experience.location} 
                        title={experience.title}
                        period={experience.period}
                        contributions={experience.contributions}
                      />
            })}   
          </div>
        </div>
      </div>

      {/* EDUCATION */}
      <div className="flex flex-col text-sm sm:text-base gap-12 mt-6">
        <div 
          className="animate-in flex flex-col md:flex-row gap-2"
          style={{ "--index": 4 } as React.CSSProperties}
        >
          <div className="md:w-32 shrink-0 text-neutral-500 dark:text-neutral-300">
          {t('presentationPage.education.title')}
          </div>
              
          <div className="flex flex-col gap-4 transition duration-300 listCompanies w-full">
            
            <div>
              <a 
                href="https://www.inf.utfsm.cl/" 
                className="flex flex-row justify-between transition duration-300 hover:cursor-pointer"
                target="_blank"
              >
                {/* mr-2 sm:mr-2 are here because on really small screens, Universidad Técnica Federico Santa María write itself
                    on 2 lines, and change the aspect of the picture in contrast with the one from INSA. This solve the problem.
                    In the INSA Section, the code is mr-0 sm:mr-2 (no right margin on really small screens. 
                */}
                <div className="w-8 sm:w-16 my-2 mr-2 sm:mr-2 shrink-0"> 
                  <img 
                    className="" 
                    src={logoUTFSM} 
                    alt="logo UTFSM" 
                  />
                </div>
                <div className="flex flex-col justify-start grow ml-2 md:ml-4">
                  <div>Ingeniero Civil Informática</div>
                  <div className="text-neutral-500 dark:text-neutral-400">
                    Universidad Técnica Federico Santa María
                  </div>
                </div>
                <div className="justify-end whitespace-nowrap text-neutral-500 dark:text-neutral-400">
                  2017 - 2019
                </div>
              </a>
            </div>

            <div>
              <a 
                href="https://www.insa-lyon.fr/fr/formation/informatique/" 
                className="flex flex-row justify-between transition duration-300 hover:cursor-pointer"
                target="_blank"
              >
                <div className="w-8 sm:w-16 my-2 mr-0 sm:mr-2 shrink-0">
                  <img 
                    className="bg-neutral-50 dark:bg-neutral-900 transition duration-500" 
                    src={logoINSA} 
                    alt="logo insa lyon" 
                  />
                </div>
                <div className="flex flex-col justify-start grow ml-4 sm:ml-2 md:ml-4">
                  <div>Ingénieur Informatique</div>
                  <div className="text-neutral-500 dark:text-neutral-400">
                    INSA Lyon
                  </div>
                </div>
                <div className="justify-end whitespace-nowrap text-neutral-500 dark:text-neutral-400">
                  2015 - 2019
                </div>
              </a>
            </div>
          </div>

        </div>
      </div>

      {/* PROJECTS */}
      {/* <div className="flex flex-col text-base gap-12 mt-6">
        <div 
          className="animate-in flex flex-col md:flex-row gap-2"
          style={{ "--index": 4 } as React.CSSProperties}
        >
          <div className="md:w-32 shrink-0 text-neutral-500 dark:text-neutral-300">
            Projects
          </div>
          <div className="text-neutral-800 dark:text-neutral-200 text-justify flex flex-col gap-2 w-full">
            Projects available on my github
          </div>
        </div>
      </div> */}

      {/* LANGUAGES*/}
      <div className="flex flex-col text-sm sm:text-base gap-12 mt-6">
        <div 
          className="animate-in flex flex-col md:flex-row gap-2"
          style={{ "--index": 5 } as React.CSSProperties}
        >
          <div className="md:w-32 shrink-0 text-neutral-500 dark:text-neutral-300">
            Languages
          </div>
          <div className="flex flex-row  gap-2 hover:cursor-default">
            <div className="flex justify-center border border-neutral-300 dark:border-neutral-500 rounded px-4 py-2">
              🇫🇷 French
            </div>
            <div className="flex justify-center border border-neutral-300 dark:border-neutral-500 rounded px-4 py-2">
              🇬🇧 English
            </div>
            <div className="flex justify-center border border-neutral-300 dark:border-neutral-500 rounded px-4 py-2">
              🇪🇸 Spanish
            </div>
          </div>
        </div>
      </div>

      {/* TECHNOLOGIES */}
      <div className="flex flex-col text-sm sm:text-base gap-12 mt-6">
        <div 
          className="animate-in flex flex-col md:flex-row gap-1"
          style={{ "--index": 4 } as React.CSSProperties}
        >
          <div className="md:w-32 shrink-0 text-neutral-500 dark:text-neutral-300">
            Technologies & Languages
          </div>
          <div className="text-neutral-800 dark:text-neutral-200 flex flex-col gap-2 w-full">
            <div className="">
              <div className="text-neutral-400">
                back-end
              </div>
              <div className="">Node.js, Express, NextJS, RESTful APIs, Flask</div>
            </div>
            <div className="">
              <div className="text-neutral-400">
                front-end
              </div>
              <div className="">HTML, CSS, React (with Redux), jQuery, Dust.js</div>
            </div>
            <div className="">
              <div className="text-neutral-400">
                programming languages
              </div>
              <div className="">Javascript (ES6+), TypeScript, Python, Java (Java EE & Android), C, C++</div>
            </div>
            <div className="">
              <div className="text-neutral-400">
                technologies & methods
              </div>
              <div className="">TDD (Mocha), Git, Agile, SQL (MySQL & Postgres), CI & CD with TOML</div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
};

export default Presentation;