
import { useTranslation } from 'react-i18next';

import logoBottomline from '../../../assets/logosCompanies/bottomline.png';
import logoFNE from '../../../assets/logosCompanies/fne.png';
import logoIgocreate from '../../../assets/logosCompanies/igocreate.png';
import logoKamoov from '../../../assets/logosCompanies/kamoov.png';


const HomePage = () => {
  const { t } = useTranslation(); 

  return (
    <div className="w-full flex flex-col gap-4 py-6">

      {/* ABOUT TITLE */}
      <div 
        className="animate-in flex flex-col mt-4"
        style={{ "--index": 1 } as React.CSSProperties}
      >
        <div className="text-lg font-bold">{t('homePage.about')}</div>
        <div className="text-base text-neutral-500 dark:text-neutral-300">{t('homePage.aboutSecondary')}</div>
      </div>

      <div className="flex flex-col text-base gap-12 mt-6">
        {/* INTRODUCTION */}
        <div 
          className="animate-in flex flex-col md:flex-row gap-2"
          style={{ "--index": 2 } as React.CSSProperties}
        >
          <div className="md:w-32 shrink-0 text-neutral-500 dark:text-neutral-300 dark:font-semibold">
            {t('homePage.about')}
          </div>
          <div className="text-neutral-800 dark:text-neutral-200 text-justify flex flex-col gap-2">
            <div>{t('homePage.helloWorld')}</div>
            <div>{t('homePage.shortPresentation')}</div>
            <div>{t('homePage.otherHobbies')}</div>
          </div>
        </div>

        {/* SOCIAL */}
        <div 
          className="flex flex-col md:flex-row gap-2 animate-in"
          style={{ "--index": 3 } as React.CSSProperties}
        >
          <div className="md:w-32 shrink-0 text-neutral-500 dark:text-neutral-300 dark:font-semibold">
            Connect
          </div>

          <div className="flex-grow grid grid-cols-1 md:grid-cols-2 gap-2 hover:text-neutral-400 transition duration-500">
            
            {/* Github */}
            <a href="https://github.com/joachimdorel" target="_blank">
              <div className="border border-neutral-300 dark:border-neutral-500 hover:text-neutral-900 hover:border-neutral-500 dark:hover:text-neutral-200 dark:hover:border-neutral-100 hover:cursor-pointer rounded p-4 transition duration-500">
                <div className="flex flex-row items-center gap-2 place-content-between">
                  <div className="flex flex-row gap-6">
                    <span className="text-xl"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg></span>
                    Github
                  </div>
                  <div className="">
                    <svg className="w-5 h-5 grow place-content-end text-secondary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd"></path></svg>
                  </div>
                </div>
              </div>
            </a>

            {/* Linkedin */}
            <a href="https://www.linkedin.com/in/joachimdorel/" target="_blank">
              <div className="border border-neutral-300 dark:border-neutral-500 hover:text-neutral-900 hover:border-neutral-500 dark:hover:text-neutral-200 dark:hover:border-neutral-100 hover:cursor-pointer rounded p-4 transition duration-500">
                <div className="flex flex-row items-center gap-2 place-content-between">
                  <div className="flex flex-row gap-6">
                    <span className="text-xl">
                      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                      </svg>
                    </span>
                    LinkedIn
                  </div>
                  <div className="">
                    <svg 
                      className="w-5 h-5 grow place-content-end text-secondary" 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 20 20" 
                      fill="currentColor"
                    >
                      <path 
                        fillRule="evenodd" 
                        d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" 
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </a>

            {/* Email */}
            <a href="mailto:steep_ticking.0y@icloud.com" target="_blank">
              <div className="border border-neutral-300 dark:border-neutral-500 hover:text-neutral-900 hover:border-neutral-500 dark:hover:text-neutral-200 dark:hover:border-neutral-100 hover:cursor-pointer rounded p-4 transition duration-500">
                <div className="flex flex-row items-center gap-2 place-content-between">
                  <div className="flex flex-row gap-6">
                    <span className="text-xl">
                      <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z"></path><path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z"></path>
                      </svg>                  
                    </span>
                    Email
                  </div>
                  <div className="">
                    <svg className="w-5 h-5 grow place-content-end text-secondary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd"></path></svg>
                  </div>
                </div>
              </div>
            </a>

            {/* Strava */}
            <a href="https://www.strava.com/athletes/11020400" target="_blank">
              <div className="border border-neutral-300 dark:border-neutral-500 hover:text-neutral-900 hover:border-neutral-500 dark:hover:text-neutral-200 dark:hover:border-neutral-100 hover:cursor-pointer rounded p-4 transition duration-500">
                <div className="flex flex-row items-center gap-2 place-content-between">
                  <div className="flex flex-row gap-6">
                    <span className="text-xl">
                      <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="currentColor">
                        <path d="m20.516 23.927l-2.786-5.49h-4.083L20.517 32l6.865-13.563h-4.083zm-6.563-12.953l3.781 7.464h5.563L13.953 0L4.62 18.438h5.557z"/>
                      </svg> 
                    </span>
                    Strava
                  </div>
                  <div className="">
                    <svg className="w-5 h-5 grow place-content-end text-secondary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd"></path></svg>
                  </div>
                </div>
              </div>
            </a>

          </div>
        </div>

        {/* EXPERIENCE */}
        <div 
          className="animate-in flex flex-col md:flex-row gap-2"
          style={{ "--index": 4 } as React.CSSProperties}
        >
          <div className="md:w-32 shrink-0 text-neutral-500 dark:text-neutral-300 dark:font-semibold">
            {t('homePage.experience.title')}
          </div>
          <div className="text-neutral-800 dark:text-neutral-200 text-justify flex flex-col gap-2">
            <div className="mb-6">
            {t('homePage.experience.subtitle')}
            </div>
            <div className="mb-6">
            {t('homePage.experience.shortPresentation')}
            </div>

            <div className="flex flex-col gap-4 transition duration-300 listCompanies">

              <div>
                <a 
                  href="https://www.igocreate.com/" 
                  className="flex flex-row justify-between transition duration-300 hover:cursor-pointer"
                  target="_blank"
                >
                  <div>
                    <img 
                      className="object-scale-down w-10 my-1 mr-4 rounded-full bg-neutral-100" 
                      src={logoIgocreate} 
                      alt="logo igocreate" 
                    />
                  </div>
                  <div className="flex flex-col justify-start grow">
                    <div>{t('homePage.experience.igocreateTitle')}</div>
                    <div className="text-neutral-500 dark:text-neutral-400">
                      Igocreate
                    </div>
                  </div>
                  <div className="justify-end text-neutral-500 dark:text-neutral-400">
                    2020 - 2022
                  </div>
                </a>
              </div>

              <div>
                <a 
                  href="https://www.bottomline.com/" 
                  className="flex flex-row justify-between transition duration-300 hover:cursor-pointer"
                  target="_blank"
                >
                  <div>
                    <img 
                      className="object-scale-down w-10 my-1 mr-4 rounded-full" 
                      src={logoBottomline} 
                      alt="logo Bottomline" 
                    />
                  </div>
                  <div className="flex flex-col justify-start grow">
                    <div>{t('homePage.experience.bottomlineTitle')}</div>
                    <div className="text-neutral-500 dark:text-neutral-400">
                      Bottomline Technologies
                    </div>
                  </div>
                  <div className="justify-end text-neutral-500 dark:text-neutral-400">
                    2017
                  </div>
                </a>
              </div>
              
              <div>
                <a 
                  href="https://www.linkedin.com/company/kamoov/" 
                  className="flex flex-row justify-between transition duration-300 hover:cursor-pointer"
                  target="_blank"
                >
                  <div>
                    <img 
                      className="object-scale-down w-10 my-1 mr-4 rounded-full" 
                      src={logoKamoov} 
                      alt="logo Kamoov" 
                    />
                  </div>
                  <div className="flex flex-col justify-start grow">
                    <div>{t('homePage.experience.kamoovTitle')}</div>
                    <div className="text-neutral-500 dark:text-neutral-400">
                      Kamoov
                    </div>
                  </div>
                  <div className="justify-end text-neutral-500 dark:text-neutral-400">
                    2016
                  </div>
                </a>
              </div>

              <div>
                <a 
                  href="https://fne.asso.fr/" 
                  className="flex flex-row justify-between transition duration-300 hover:cursor-pointer"
                  target="_blank"
                >
                  <div>
                    <img 
                      className="object-scale-down w-10 my-1 mr-4 rounded-full" 
                      src={logoFNE} 
                      alt="logo FNE" 
                    />
                  </div>
                  <div className="flex flex-col justify-start grow">
                    <div>{t('homePage.experience.fneTitle')}</div>
                    <div className="text-neutral-500 dark:text-neutral-400">
                      FNE
                    </div>
                  </div>
                  <div className="justify-end text-neutral-500 dark:text-neutral-400">
                    2015
                  </div>
                </a>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default HomePage;