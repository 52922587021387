import TypeIt from "typeit-react";
import '../../styles/home/Intro.css';

const Intro = () => {
  return (
    <div className='intro'>
      <div id="helloWorld">
        <TypeIt avgtypingdelay={100} >
          Hello world, Joachim here.
        </TypeIt>
      </div>

      {/* Manage the 'i code things' panel showing up from the bottom of the screen  */}
      <div id="introDetails"
        data-aos="fade-up"
        data-aos-easing="ease-in"        
        data-aos-duration="1500"
        data-aos-delay="3000"
      >
        <div id="iCodeThings">
          I code things.
        </div>

        <div id="presentation">
          I'm a software engineer experienced in Node.js, TypeScript, and React. 
          I build full-stack apps and love exploring human-computer interactions and AI. 
          Let's work together!
        </div>

        <div id="buttonEmail">
          <a href="mailto:joachim.dorel@me.com">Contact me</a>
        </div>

      </div>

    </div>
  )
};

export default Intro;