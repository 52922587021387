import React from 'react';
import '../../styles/home/Home.css';
import "@fontsource/ibm-plex-sans";
import AOS from 'aos'; // AOS for Animate On Scroll 
import 'aos/dist/aos.css';

import Intro from './Intro';
import Experience from './Experience';
import Education from './Education';
import Skills from './Skills';
import Projects from './Projects';
import Footer from './Footer';

function Home() {
  AOS.init();
  
  return (
    <div className="home">
      <div id="content">
        <div className='section alignedSection'>
          <Intro  />
        </div>
        <div className='sectionGeneral'>
          <Experience />
          <Education  />
          {/* <Skills /> */}
          {/* <Projects /> */}
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home;
