import React, { useState, useEffect } from 'react';
import '../../styles/mondrian/Mondrian.css';

const numRows = 25;
const numCols = 25;

type Position = {
  x: number;
  y: number;
};

type GridCell = 0 | 1 | 2;
/*
0: empty cell
1: path cell
2: player cell
*/

const initialGrid: GridCell[][] = Array(numRows).fill([]).map(() => Array(numCols).fill(0));

// lines for rows
for (let i = 0; i < numCols; i++) {
    initialGrid[0][i] = 1;
    initialGrid[numCols - 1][i] = 1;
    initialGrid[12][i] = 1;
}

// lines for cols
for (let i = 0; i < numRows; i++) {
  initialGrid[i][0] = 1;
  initialGrid[i][12] = 1;
  initialGrid[i][numRows-1] = 1;
}

initialGrid[12][12] = 2; // player

const Mondrian: React.FC = () => {
  const [playerPosition, setPlayerPosition] = useState<Position>({ x: 12, y: 12 });
  const [grid, setGrid] = useState<GridCell[][]>(initialGrid);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      setGrid(prevGrid => {
        let newX = playerPosition.x;
        let newY = playerPosition.y;

        switch (e.key) {
          case 'ArrowUp':
            if (newY > 0 && prevGrid[newY - 1][newX] === 1) {
              newY -= 1;
            }
            break;
          case 'ArrowDown':
            if (newY < numRows - 1 && prevGrid[newY + 1][newX] === 1) {
              newY += 1;
            }
            break;
          case 'ArrowLeft':
            if (newX > 0 && prevGrid[newY][newX - 1] === 1) {
              newX -= 1;
            }
            break;
          case 'ArrowRight':
            if (newX < numCols - 1 && prevGrid[newY][newX + 1] === 1) {
              newX += 1;
            }
            break;
        }

        if (newX !== playerPosition.x || newY !== playerPosition.y) {
          const newGrid = prevGrid.map(row => row.slice());
          newGrid[playerPosition.y][playerPosition.x] = 1;  // Reset current position to path
          newGrid[newY][newX] = 2;  // Set new position to player
          setPlayerPosition({ x: newX, y: newY });
          return newGrid;
        }

        return prevGrid;
      });
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [playerPosition]);

  return (
    <div className="GameArea">
      {grid.map((row, rowIndex) => (
        <div key={rowIndex} className="Row">
          {row.map((cell, colIndex) => (
            <div key={colIndex} className={`Cell ${cell === 1 ? "Path" : ""}`}>
              {cell === 2 && <div className="Player"></div>}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Mondrian;