const loadClickyScript = (siteId: number | string) => {
  if (!window.clicky) {
    const clickyScript = document.createElement("script");
    clickyScript.async = true;
    clickyScript.src = '//static.getclicky.com/js';
    document.body.appendChild(clickyScript);

    window.clicky_site_ids = window.clicky_site_ids || [];
    window.clicky_site_ids.push(siteId);
  } else {
    window.clicky.init(siteId);
  }
};

export default loadClickyScript;