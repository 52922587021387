import { useState } from 'react';

import '../../styles/home/Sections.css';

type Link = {
  title: string,
  link: string
};

type SectionDetailsType = {
  id: number,
  mainTitle: string,
  symbol: string,
  period: string,
  location: string,
  title: string,
  presentation: string,
  tasks: string[],
  links?: Link[]
};

const Education = () => {
  const [ sectionDisplayed, setSectionDisplayed ] = useState(0);

  const sections: SectionDetailsType[] = [
    {
      id: 0,
      mainTitle: 'Universidad Técnica Federico Santa María',
      symbol: 'UTFSM',
      period: 'July 2017 - December 2019',
      location: 'Valparaíso, Chile',
      title: 'Master of Science, Computer Science and Information Technology',
      presentation: 'Ingeniero Civil Informático - Double degree with INSA Lyon',
      tasks: [
        'Double degree between UTFSM and INSA Lyon',
        'First student to realize the double degree',
        'Finished the degree with a mémoire on blockchains and a project allowing to track drugs and their supply chain in a blockchain'
      ],
      links: [
        { 
          title: 'Rinde su examen el primer estudiante de pregrado aceptado en convenio de doble titulación',
          link: 'https://www.inf.utfsm.cl/noticias/342-primer-titulado-convenio-doble-titulacion-francia'
        }
      ]
    },
    {
      id: 1,
      mainTitle: 'Institut National des Sciences Appliquées de Lyon',
      symbol: 'INSA Lyon',
      period: 'September 2015 - December 2019',
      location: 'Lyon, France',
      title: 'Master of Science, Computer Science and Information Technology',
      presentation: 'Diplôme d\'Ingénieur spécialité Génie Informatique',
      tasks: []
    },
    {
      id: 2,
      mainTitle: 'IUT Université Sorbonne Paris Nord',
      symbol: 'IUT Paris',
      period: 'January 2013 - July 2015',
      location: 'Paris, France',
      title: 'Bachelor of Technology in Computer Science',
      presentation: 'Diplôme Universitaire de Technologie spécialité Informatique',
      tasks: []
    },
  
  ];

  return (
    <div className='sectionPage' id='education' >
      <div className='sectionsTitle'>
        Education
        {/* <a className='anchor' href='#experience'>previous</a> */}
      </div>
      <div className='sectionsTable'>
        <div className='sectionSelector'>
          {sections.map(( section: SectionDetailsType ) => {
            return (
              <div 
                key={section.id}
                className={'sectionSelectorItem ' + (section.id === sectionDisplayed ? 'sectionSelectorSelected' : '') }
                onClick={() => {
                  setSectionDisplayed(section.id);
                }}
              >
                {section.symbol}
              </div>
            )
          })}
        </div>
        <div className='sectionDetails'>

          <div className='sectionTitleLocation'>
            <div className='sectionMainTitle'>
              {sections[sectionDisplayed].mainTitle}
            </div>
            <div className='sectionLocation'>
              {sections[sectionDisplayed].location} 📍
            </div>
          </div>

          <div className='sectionInformations'>
            <div className='sectionInformationTitle'>
              {sections[sectionDisplayed].title}
            </div>
            <div className='sectionPeriod'>
              {sections[sectionDisplayed].period}
            </div>
          </div>

          <div className='sectionPresentation'>
            {sections[sectionDisplayed].presentation}
          </div>

          <div className='sectionTasks'>
            {
              sections[sectionDisplayed].tasks.map(( task: string, index: number ) => {
                return (
                  <div key={task[2]+index}>• {task}</div>
                )
              })
            }
          </div>

          <div className='sectionLinks'>
            {
              sections[sectionDisplayed].links?.map(( link: Link, index: number ) => {
                return (
                  <div key={'linkNumber'+index}>
                    <a href={link.link}>
                      ↗️ {link.title}
                    </a>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default Education;