import { useState } from 'react';

import '../../styles/home/Sections.css';

type ExperienceType = {
  id: number,
  company: string,
  symbol: string,
  period: string,
  location: string,
  title: string,
  presentation: string,
  tasks: string[]
};

const Experience = () => {
  const [experienceDisplayed, setExperienceDisplayed] = useState(0);

  const experiences: ExperienceType[] = [
    {
      id: 0, 
      company: 'Igocreate',
      symbol:'Igocreate', 
      period: 'March 2020 - November 2022',
      location: 'Paris, France',
      title: 'Full Stack Software Engineer',
      presentation: '',
      tasks: [
        'worked on several platforms, mainly Witik, a web app helping companies to respect the new GDPR rules in Europe, follow their processes, automate several of them and create courses to teach those rules.',
        'developed the back-ends of the platforms with Node.JS and Express.js, and the front with a combination of React and Dust.js, a javascript templating engine.',
        'build a PDF export service from the GDPR register using PDFKit, a PDF generation library for Node JS.',
        'wrote several tests (mainly functionals) using Mocha following TDD processes allowing us to cover more than 80% of our code and implemented continuous integration in TOML using GitLab CI.',
        'participated in meetings with stakeholders, with discussions going from the current state of the different platforms to the interactions with the users and the platforms evolutions.',
        'realized weekly code review and peer programming sessions with 2 interns, introducing them to the best practices and guidelines.'
      ]
    },
    {
      id: 1, 
      company: 'Bottomline Technologies',
      symbol:'Bottomline', 
      period: 'April 2017 - August 2017',
      location: 'Geneva, Switzerland',
      title: 'Full Stack Software Engineer',
      presentation: '',
      tasks: [
        '5 months internship during which I worked on a Javascript interface allowing users to visualize and edit data representing workflows from an SQL database.',
        'programmed a Java back-end allowing to access the SQL data and to interact with PL/SQL, the programming language used in the previous version of the workflows.',
        'worked with one other intern on this feature, in a team of 10 people, following agile practices with a daily scrum meeting, two weeks sprints and regular code reviews.',
      ]
    },
    {
      id: 2, 
      company: 'Kamoov',
      symbol:'Kamoov', 
      period: 'June 2016 - August 2016',
      location: 'Paris, France',
      title: 'Software Engineer Internship',
      presentation: '',
      tasks: [
        '3 months internship in a small startup incubated at Telecom ParisTech, working on an iOS live streaming app.',
        'worked in Objective-C to develop the several features of the app.',
        'developed part of the interface following mock-ups and instructions from the start-up founders, improved the stability of the apps and added social features.',
      ]
    },
    // {
    //   id: 3,
    //   company: 'France Nature Environnement',
    //   symbol: 'FNE',
    //   period: 'April 2015 - July 2015',
    //   location: 'Paris, France',
    //   title: '',
    //   presentation: '',
    //   tasks: [ '' ]
    // }
  ];

  return (
    <div className='sections alignedSection' id='experience'>
      <div className='sectionsTitle'>
        Work Experience
        {/* <a className='anchor' href='#education'>next</a> */}
      </div>
      <div className='sectionsTable'>
        <div className='sectionSelector'>
          {experiences.map(( experience: ExperienceType ) => {
            return (
              <div 
                key={experience.id}
                className={'sectionSelectorItem ' + (experience.id === experienceDisplayed ? 'sectionSelectorSelected' : '') }
                onClick={() => {
                  setExperienceDisplayed(experience.id);
                }}
              >
                {experience.symbol}
              </div>
            )
          })}
        </div>

        <div className='sectionDetails'>

          <div className='sectionTitleLocation'>
            <div className='sectionMainTitle'>
              {experiences[experienceDisplayed].company}
            </div>
            <div>
              {experiences[experienceDisplayed].location} 📍
            </div>
          </div>

          <div className='sectionInformations'>
            <div>
              {experiences[experienceDisplayed].title}
            </div>
            <div>
              {experiences[experienceDisplayed].period}
            </div>
          </div>

          <div>
            {experiences[experienceDisplayed].presentation}
          </div>

          <div className='sectionTasks'>
              {experiences[experienceDisplayed].tasks.map(( task: string, index: number ) => {
              return (
                <div key={task[2]+index}>• {task}</div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Experience;
